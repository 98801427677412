import React, { FC } from "react";
import Link from "next/link";
import styled from "styled-components";
import { opacityHover } from "../styles/atoms";
import { Query } from "react-apollo";
import { GET_BAR } from "../../queries/BarCollections";
import getUndef from "../../lib/get-undef";

const BarHeader: FC<{}> = () => {
  return (
    <Query<any>
      query={GET_BAR}
      variables={{
        slug: "123",
      }}
    >
      {({ loading, error, data }) => {
        if (error || loading) {
          return null;
        }

        const navItems = getUndef(() => data.shop.navigations[0].lineItems);

        return (
          <>
            {navItems.map((i) => {
              return (
                <Link href="/[page]" as={`/${i.slug}`} key={i.id}>
                  <LinkEl href={`/${i.slug}`}>{i.name}</LinkEl>
                </Link>
              );
            })}
            <LinkEl href="http://mikafoodwork.ru/" target="_blank">
              Вакансии
            </LinkEl>
            {/* <LinkEl href="https://mikafoodfranch.ru/" target="_blank">
              Франшиза
            </LinkEl> */}
          </>
        );
      }}
    </Query>
  );
};

const LinkEl = styled.a`
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 10px;
  ${opacityHover};

  @media (max-width: 1200px) {
    display: none;
  }
`;

export default BarHeader;
