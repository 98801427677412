import { createGlobalStyle } from "styled-components";

import boxSizing from "./_box-sizing";
import normalize from "./_normalize";
import focus from "./_focus";
import fonts from "./_fonts";
import scroll from "./_scroll";
import fitImages from "./_fit-images";
import modal from "./_modal";

const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${focus}
  ${fonts}
  ${boxSizing}
  ${scroll}
  ${fitImages}
  ${modal};
`;

export default GlobalStyles;
