import React, { FC, createContext, useState, useEffect } from "react";
import getUndef from "../../lib/get-undef";
import getBalance from "../../lib/getBalance";
import getCard from "../../lib/getCard";
import { checkAuth_customer } from "../../__generated__/checkAuth";

export interface AuthorizationInterface {
  show: boolean;
  avatar: any;
  isAuthorized?: boolean;
  customer?: checkAuth_customer;
  balance?: number;
  balanceLoading: boolean;
  card: string;
  cardLoading: boolean;
  setShow: (val: boolean) => void;
  setCustomer: React.Dispatch<any>;
}

const AuthorizationContext = createContext<AuthorizationInterface>({
  show: false,
  avatar: "",
  isAuthorized: false,
  customer: null,
  balance: undefined,
  balanceLoading: true,
  card: "",
  cardLoading: true,
  setShow: () => {},
  setCustomer: () => {},
});

export default AuthorizationContext;

interface Props {
  client: any;
  initAuth: any;
}

export const AuthorizationProvider: FC<Props> = ({
  children,
  client,
  initAuth,
}) => {
  const [customer, setCustomer] = useState(null);
  const [avatar, setAvatar] = useState("");

  const [balance, setBalance] = useState<number>();
  const [balanceLoading, setBalanceLoading] = useState(true);

  const [card, setCard] = useState<string>("");
  const [cardLoading, setCardLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const customer = await initAuth(client);
      if (!customer) {
        return;
      }
      setCustomer(customer);

      async function fetchCard() {
        setBalanceLoading(true);
        const result = await getBalance(client, customer.phone);
        setBalance(result);
        setBalanceLoading(false);
      }

      async function fetchBalance() {
        setCardLoading(true);
        const cardResult = await getCard(client, customer.phone);
        setCard(cardResult);
        setCardLoading(false);
      }

      fetchCard();
      fetchBalance();
    }
    init();
  }, []);

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (customer) {
      setAvatar(getUndef(() => customer.avatar));
    }
  }, [customer]);

  return (
    <AuthorizationContext.Provider
      value={{
        show,
        avatar,
        isAuthorized: !!customer,
        customer,
        balance,
        balanceLoading,
        card,
        cardLoading,
        setShow: (val) => {
          setShow(val);
        },
        setCustomer,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
