import React, { FC, useContext } from "react";
import styled from "styled-components";
import { CityContext } from "../context/CityContext";

const FooterText: FC = () => {
  const { cityNameForSeo } = useContext(CityContext);

  return (
    <Container>
      <h1 className="h3">Вкусная еда с доставкой в {cityNameForSeo}</h1>
      <p>
        Сеть пиццерий МИКА ФУД — это успешно развивающийся ресторан быстрого
        питания, где можно не только вкусно покушать, но и отдохнуть в
        комфортной дружелюбной атмосфере.
      </p>
      <p>
        У нас вы найдете большой ассортимент пиццы, выпечки, салатов на любой
        вкус, а так же можете попробовать настоящие шашлыки на углях, ароматную
        шаурму, горячую печеную картошку с салатиками, картофель фри, наггетсы,
        фирменные хачапури по-аджарски и многое другое.
      </p>
      <p>
        Для вашего удобства работает служба доставки, где вы можете заказать
        любую нашу продукцию.
      </p>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px 0;

  @media (max-width: 800px) {
    padding-bottom: 20px;
  }
`;

export default FooterText;
