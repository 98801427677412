import { css } from "styled-components";
import { TRANSITION_TIME_SLOW, TRANSITION_TIME_BASE } from "./constants";

const modal = css`
  .custom-overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .without-overlay {
    background: transparent !important;
  }

  .custom-overlay-space-on-top {
    padding-top: 50px;
  }

  .custom-modal {
    padding: 0;
    max-width: 440px;
    width: 100%;
    border-radius: 20px;
  }

  .custom-modal-wide {
    max-width: 510px;
  }

  .custom-modal-wider {
    max-width: 560px;
  }

  .custom-modal-warning {
    max-width: 365px;
    width: 100%;
    margin: auto;
    padding: 40px 35px;
    background: #ffffff;
    box-shadow: 22px 44px 44px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }

  .custom-modal-thanks-page {
    margin: auto;
    background: transparent;
    padding: 0;
    max-width: 100%;

    box-shadow: none;

    @media (max-width: 900px) {
      margin-top: 50px;
    }

    .customized-swiper-button-next,
    .customized-swiper-button-prev {
      background-size: contain;
      background-repeat: no-repeat;

      &:after {
        display: none;
      }

      &:focus {
        outline: none;
      }
    }

    .customized-swiper-button-next {
      background-image: url("../static/icons/arrow.svg");
    }

    .customized-swiper-button-prev {
      background-image: url("../static/icons/arrow.svg");
      transform: rotate(180deg);
    }
  }

  .custom-close {
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: ${TRANSITION_TIME_SLOW}ms ease-in;
    &:hover {
      transition: ${TRANSITION_TIME_BASE}ms ease-out;
      opacity: 0.5;
    }
  }

  .custom-close-40 {
    right: 40px;
  }

  .custom-close-white {
    top: 1.375rem;
    right: 1.375rem;
    cursor: pointer;
    transition: ${TRANSITION_TIME_SLOW}ms ease-in;
    fill: #fff;
    z-index: 1000;

    path {
      fill: #000;
    }

    &:hover {
      transition: ${TRANSITION_TIME_BASE}ms ease-out;
      opacity: 0.5;
    }
  }

  .custom-overlay-add-additions {
    @media (max-width: 48rem) {
      padding: 16px 12px;
      overflow: hidden;

      .custom-modal-wide {
        max-width: 510px;
      }

      .custom-overlay-space-on-top {
        padding-top: 16px;
      }
    }

    @media (max-width: 20rem) {
      .custom-close {
        right: 8px;
      }
    }
  }
`;

export default modal;
