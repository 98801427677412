import { parseCookies } from "nookies";
import gql from "graphql-tag";
import getUndef from "./get-undef";

const GET_CHECKOUT = gql`
  query checkAuth {
    customer {
      id
      phone
      firstName
      avatar {
        id
        url
      }
    }
  }
`;

export default async function initAuth(client) {
  const token = parseCookies().token;

  async function checkAuth() {
    if (token) {
      try {
        const { data } = await client.query({
          query: GET_CHECKOUT,
        });

        return getUndef(() => data.customer);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  return await checkAuth();
}
