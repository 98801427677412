import { parseCookies, setCookie } from "nookies";
import { getCheckout, createCheckout, getCompleted } from "checkout/actions";
import { getCheckout_checkout } from "../__generated__/getCheckout";

export default async function initCheckout(client: any): Promise<{
  checkout: getCheckout_checkout;
  checkoutId: string;
}> {
  // Get checkoutId from cookies
  let checkoutId = parseCookies().checkoutId;

  if (checkoutId === "null") {
    checkoutId = null;
  }

  const completed = checkoutId ? await getCompleted(client, checkoutId) : false;

  // If checkoutId is not found or completed create one
  if (!checkoutId || completed) {
    checkoutId = await createCheckout(client);
    setCookie(null, "checkoutId", checkoutId, {
      path: "/",
      domain: window.location.hostname,
      sameSite: "Strict",
      maxAge: 30 * 24 * 60 * 60,
    });
  }

  const checkout = checkoutId && (await getCheckout(client, checkoutId));

  return {
    checkoutId,
    checkout,
  };
}
