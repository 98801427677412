import { ApolloClient } from "apollo-client";
import create from "./create-apollo";

let apolloClient: ApolloClient<any> | null = null;

export default function initApollo(initialState, options) {
  if (typeof window === "undefined") {
    return create(initialState, options);
  }

  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
