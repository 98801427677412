import React, { FC, SetStateAction, Dispatch } from "react";
import styled from "styled-components";
import { GRAY_LIGHT } from "../styles/constants";
import Link from "next/link";
import { opacityHover } from "../styles/atoms";
import Social from "./Social";
import BarCollections from "./BarCollections";
import SearchInput from "../fields/SearchInput";
import getUndef from "../../lib/get-undef";
import { Query } from "react-apollo";
import { GET_BAR } from "../../queries/BarCollections";
import { products, productsVariables } from "../../__generated__/products";
import { GET_PRODUCTS } from "../../queries/Products";

const addNavItems = [
  {
    name: "Акции",
    href: "/promo",
    icon: "/static/icons/sale.svg",
    color: "#78B833",
  },
  {
    name: "Новинки",
    as: "/catalog/new",
    href: "/catalog/[code]",
    icon: "/static/icons/new.svg",
    color: "#FF8020",
  },
  {
    name: "Хит Продаж",
    as: "/catalog/popular",
    href: "/catalog/[code]",
    icon: "/static/icons/hit.svg",
    color: "#A9228B",
  },
  {
    name: "Острое",
    as: "/catalog/spicy",
    href: "/catalog/[code]",
    icon: "/static/icons/spicy.svg",
    color: "#E61917",
  },
];

interface INavbar {
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
}
const Navbar: FC<INavbar> = ({ setMenuIsOpen }) => {
  return (
    <Query
      query={GET_BAR}
      variables={{
        slug: "footer-menu",
      }}
    >
      {({ loading, error, data }: any) => {
        if (error || loading) {
          return null;
        }

        const mobileNavItems = getUndef(
          () => data.shop.navigations[0].lineItems
        );

        return (
          <Query<products, productsVariables>
            query={GET_PRODUCTS}
            variables={{
              where: {
                slug: "new",
              },
              productsWhere: {
                active: true,
                variants_some: {
                  id_not: null,
                },
              },
            }}
          >
            {({ data: dataNew }) => {
              const collectionsNew = getUndef(
                () => dataNew.shop.collections[0]
              );
              const productsLengthNew = getUndef(
                () => collectionsNew.products.length
              );

              return (
                <Query<products, productsVariables>
                  query={GET_PRODUCTS}
                  variables={{
                    where: {
                      slug: "popular",
                    },
                    productsWhere: {
                      active: true,
                      variants_some: {
                        id_not: null,
                      },
                    },
                  }}
                >
                  {({ data: dataPopular }) => {
                    const collectionsPopular = getUndef(
                      () => dataPopular.shop.collections[0]
                    );
                    const productsLengthPopular = getUndef(
                      () => collectionsPopular.products.length
                    );

                    return (
                      <>
                        <SearchInput setMenuIsOpen={setMenuIsOpen} />
                        <Container onClick={() => setMenuIsOpen(false)}>
                          <BarCollections />
                          <Separator />
                          <AddNav className="kabel">
                            {addNavItems.map((i) => {
                              if (!productsLengthNew && i.name === "Новинки") {
                                return null;
                              }
                              if (
                                !productsLengthPopular &&
                                i.name === "Хит Продаж"
                              ) {
                                return null;
                              }
                              return (
                                <Link
                                  href={i.href}
                                  as={i.as}
                                  key={i.href + i.as}
                                >
                                  <AddItem href={i.as}>
                                    <span
                                      className="icon"
                                      style={{
                                        backgroundImage: `url(${i.icon})`,
                                      }}
                                    />
                                    <span
                                      className="text"
                                      style={{
                                        color: i.color,
                                      }}
                                    >
                                      {i.name}
                                    </span>
                                  </AddItem>
                                </Link>
                              );
                            })}
                          </AddNav>
                          <Mobile>
                            <Separator />
                            {mobileNavItems && (
                              <MobileNav>
                                <MobileNavItem
                                  href="http://mikafoodwork.ru/"
                                  target="_blank"
                                >
                                  Вакансии
                                </MobileNavItem>
                                {mobileNavItems.map((i) => {
                                  const haveHref = getUndef(() =>
                                    i.params.value.includes("http")
                                  );
                                  const url = haveHref
                                    ? i.params.value
                                    : `/${i.slug}`;

                                  return (
                                    <Link
                                      key={url}
                                      href={
                                        url === "/promo" ||
                                        url === "/reviews" ||
                                        url === "/thanks"
                                          ? url
                                          : "/[page]"
                                      }
                                      as={url}
                                    >
                                      <MobileNavItem
                                        href={url}
                                        target={haveHref ? "_blank" : undefined}
                                        rel={
                                          haveHref
                                            ? "noopener noreferrer"
                                            : undefined
                                        }
                                      >
                                        {i.name}
                                      </MobileNavItem>
                                    </Link>
                                  );
                                })}
                                {/* <MobileNavItem
                                  href="https://mikafoodfranch.ru/"
                                  target="_blank"
                                >
                                  Франшиза
                                </MobileNavItem> */}
                              </MobileNav>
                            )}
                            <Separator />
                            <SocialHeader />
                            <Separator />
                          </Mobile>
                        </Container>
                      </>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const SocialHeader = styled(Social)`
  margin: 15px 0;
`;

const Container = styled.div`
  padding: 0 26px 18px;
`;

const Separator = styled.hr`
  display: block;
  margin: 0;
  height: 1px;
  background: none;
  border: none;
  border-bottom: 1px solid ${GRAY_LIGHT};
`;

const TopSeparator = styled(Separator)`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Item = styled.a`
  display: block;
  padding: 5px 0;
  ${opacityHover};
`;

const AddNav = styled.nav`
  padding: 10px 0;
  font-size: 16px;

  @media (max-width: 600px) {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;
  }
`;

const AddItem = styled(Item)`
  display: flex;
  align-items: center;
  ${opacityHover};

  .icon,
  .text {
    display: inline-block;
  }

  .icon {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media (max-width: 600px) {
    margin-right: 25px;

    .text {
      color: #fff !important;
    }
  }
`;

const Mobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;

const MobileNav = styled.nav`
  margin: 20px 0;
`;

const MobileNavItem = styled.a`
  display: block;
  font-size: 19px;
  padding: 7px 0;
  ${opacityHover};
`;

export default Navbar;
