import React, { useRef } from "react";
import styled from "styled-components";
import Router from "next/router";

const SearchInput = ({ setMenuIsOpen }) => {
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputRef.current.value.trim()) return;
    Router.push({
      pathname: "/search",
      query: { q: encodeURI(inputRef.current.value.trim()) },
    });
    setMenuIsOpen(false);
    inputRef.current.value = "";
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <ButtonIcon type="submit">
          <Icon src="/static/icons/search-icon.svg" alt="поиск" />
        </ButtonIcon>
        <Input ref={inputRef} type="search" placeholder="Поиск" />
      </InputWrapper>
    </Form>
  );
};

const Input = styled.input`
  background: #f5f4f0;
  border: none;
  height: 42px;
  font-size: 14px;
  width: 130px;

  @media (max-width: 600px) {
    font-size: 18px;
    flex: 1;
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #f5f4f0;
  margin-left: 26px;

  @media (max-width: 600px) {
    width: 100%;
    flex: 1;
    padding: 0 1rem;
    margin-left: 0px;
    border-radius: 10px;
  }
`;

const Form = styled.form`
  width: 100%;
  background: #f5f4f0;

  @media (max-width: 600px) {
    display: flex;
    padding: 0 25px;
    background: none;
    background-position: 20px 14px;
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

const Icon = styled.img`
  align-self: center;
  margin-top: 4px;
  user-select: none;

  width: 15px;
  height: 15px;
`;

const ButtonIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: none;
  margin: 0 8px 1px 0;
  padding: 0;
  cursor: pointer;
`;

export default SearchInput;
