import React, { FC } from "react";
import styled from "styled-components";
import { GRAY_LIGHT } from "../styles/constants";
import { opacityHover } from "../styles/atoms";

const items = [
  {
    icon: "/static/icons/vk.svg",
    iconBlack: "/static/icons/vk-black.svg",
    url: "https://vk.com/mika.food",
  },
];

const Social: FC<{ black?: boolean }> = ({ black, ...props }) => {
  return (
    <Row {...props}>
      {items.map((i) => (
        <Item
          key={i.icon}
          icon={black ? i.iconBlack : i.icon}
          href={i.url}
          target="_blank"
          rel="noopener noreferrer"
        />
      ))}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.a`
  display: inline-block;
  flex-grow: 1;
  height: 50px;
  background: ${({ icon }: { icon: string }) =>
    `url(${icon}) no-repeat center`};
  &:not(:last-child) {
    border-right: 1px solid ${GRAY_LIGHT};
  }
  ${opacityHover};
`;

export default Social;
