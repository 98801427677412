import React, { FC } from "react";
import styled from "styled-components";
import Link from "next/link";
import { Query } from "react-apollo";
import { opacityHover } from "../styles/atoms";
import {
  getBarCollections,
  getBarCollectionsVariables,
} from "../../__generated__/getBarCollections";
import getUndef from "../../lib/get-undef";
import { GET_BAR } from "../../queries/BarCollections";

const BarCollections: FC<{}> = () => {
  return (
    <Query<getBarCollections, getBarCollectionsVariables>
      query={GET_BAR}
      variables={{
        slug: "catalog-menu",
      }}
    >
      {({ loading, error, data }) => {
        if (error || loading) {
          return null;
        }

        const navItems = getUndef(() => data.shop.navigations[0].lineItems);

        return (
          <Nav className="kabel">
            {navItems &&
              navItems.map((i) => (
                <Link
                  href="/catalog/[code]"
                  as={`/catalog/${i.slug}`}
                  key={i.id}
                >
                  <Item href={`/catalog/${i.slug}`}>{i.name}</Item>
                </Link>
              ))}
          </Nav>
        );
      }}
    </Query>
  );
};

const Nav = styled.nav`
  font-size: 18px;
  padding: 15px 0;

  @media (max-width: 1200px) {
    padding-top: 0;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 1.5;
  }
`;

const Item = styled.a`
  display: block;
  padding: 5px 0;
  ${opacityHover};
`;

export default BarCollections;
