import React, { FC } from "react";
import styled from "styled-components";

const AppBanner: FC<{ isAndroid: boolean; closeHandler: () => void }> = ({
  isAndroid,
  closeHandler,
}) => {
  return (
    <Wrapper>
      <Close onClick={() => closeHandler()}>
        <div />
        <div />
      </Close>
      <ImageWrapper>
        <img src="/static/favicon/android-icon-192x192.png" />
      </ImageWrapper>
      <DescriptionWrapper>
        <Description>MikaFood</Description>
        <Description>
          Доступно в {isAndroid ? "Google Play" : "AppStore"}
        </Description>
      </DescriptionWrapper>

      <Link
        target="_blank"
        onClick={() => closeHandler()}
        href={
          isAndroid
            ? "https://play.google.com/store/apps/details?id=ru.mikafood.app"
            : "https://apps.apple.com/ru/app/mikafood/id1611756817?l=en"
        }
      >
        Открыть
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 111;
  background: #fff;
  padding: 0.625rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 12px rgb(128 168 18 / 43%);

  @media (min-width: 42rem) {
    display: none;
  }
`;

const Close = styled.button`
  background: none;
  border: none;
  flex-shrink: 0;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  height: 1rem;
  justify-content: space-between;
  padding: 0;
  width: 1.25rem;

  div {
    height: 1px;
    width: 100%;
    background: #b4b4b4;
    transform-origin: 0 0;

    &:first-child {
      transform: rotate(45deg) translate3d(1px, 0, 0);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;

const ImageWrapper = styled.figure`
  margin: 0 0.625rem;
  width: 4rem;
  height: 4rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const DescriptionWrapper = styled.div`
  flex-grow: 1;
`;

const Description = styled.p`
  margin: 0;

  &:first-child {
    font-weight: bold;
  }

  &:not(:first-child) {
    font-size: 0.625rem;
  }
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  max-width: 5rem;
  width: 100%;
  text-align: center;
  background: #78b833;
  border-radius: 0.625rem;
  color: #fff;
  box-shadow: 0px 6px 12px rgb(128 168 18 / 43%);
`;

export default AppBanner;
