import { css } from "styled-components";

const scroll = css`
  html {
    /* scroll-behavior: smooth;
    scroll-margin-top: 50px; */
  }
`;

export default scroll;
