import React, { FC } from "react";
import Link from "next/link";
import styled from "styled-components";
import { opacityHover } from "../styles/atoms";
import { Query } from "react-apollo";
import { GET_BAR } from "../../queries/BarCollections";
import getUndef from "../../lib/get-undef";

const BarFooter: FC<{}> = () => {
  return (
    <Query
      query={GET_BAR}
      variables={{
        slug: "footer-menu",
      }}
    >
      {({ loading, error, data }: any) => {
        if (error || loading) {
          return null;
        }

        const navItems = getUndef(() => data.shop.navigations[0].lineItems);

        return (
          <>
            <NavItem href="http://mikafoodwork.ru/" target="_blank">
              Вакансии
            </NavItem>
            {navItems.map((i) => {
              const haveHref = getUndef(() => i.params.value.includes("http"));
              const url = haveHref ? i.params.value : `/${i.slug}`;

              return (
                <Link
                  key={url}
                  href={
                    url === "/promo" || url === "/reviews" || url === "/thanks"
                      ? url
                      : "/[page]"
                  }
                  as={url}
                >
                  <NavItem
                    href={url}
                    target={haveHref ? "_blank" : undefined}
                    rel={haveHref ? "noopener noreferrer" : undefined}
                    className={haveHref ? "outside" : undefined}
                  >
                    {i.name}
                  </NavItem>
                </Link>
              );
            })}
            {/* <NavItem href="https://mikafoodfranch.ru/" target="_blank">
              Франшиза
            </NavItem> */}
          </>
        );
      }}
    </Query>
  );
};

const NavItem = styled.a`
  font-size: 20px;
  line-height: 2;
  ${opacityHover};

  &:not(:first-child) {
    margin-left: 10px;
  }

  &.outside {
    &:after {
      display: inline-block;
      margin-left: 10px;
      width: 15px;
      height: 15px;
      background: url("/static/icons/outside.svg") no-repeat center;
      vertical-align: baseline;
      content: "";
    }
  }

  @media (max-width: 1400px) {
    font-size: 19px;
  }

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`;

export default BarFooter;
