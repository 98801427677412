import React, { Component } from "react";
import initApollo from "./init-apollo";
import Head from "next/head";
import { getDataFromTree } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { parseCookies } from "nookies";
import * as Sentry from "@sentry/browser";
import getUndef from "./get-undef";

export default (App) => {
  return class Apollo extends Component {
    public apolloClient: ApolloClient<any> | null;
    static displayName = "withApollo(App)";
    static async getInitialProps(ctx) {
      const {
        Component,
        router,
        ctx: { req, res },
      } = ctx;

      const token = parseCookies(ctx.ctx).token;

      const host = getUndef(() => req.headers["host"]);
      const city =
        host === "beloretsk.mikafood.ru" ? "beloretsk" : "magnitogorsk";

      const apollo = initApollo(
        {},
        {
          getToken: () => token,
          getCity: () => city,
        }
      );

      ctx.ctx.apolloClient = apollo;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      if (res && res.finished) {
        return {};
      }

      if (typeof window === "undefined") {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <App
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error("Error while running `getDataFromTree`", error);
          Sentry.captureException(error);
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        token,
        city,
      };
    }

    constructor(props) {
      super(props);
      this.apolloClient = initApollo(props.apolloState, {
        getToken: () => props.token,
        getCity: () => props.city,
      });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};
