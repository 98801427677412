import gql from "graphql-tag";
import getUndef from "./get-undef";

const GET_CARD = gql`
  query customerCardNumber($phone: String!) {
    customerCardNumber(phone: $phone)
  }
`;

export default async function getCard(client, phone) {
  if (!phone) {
    return;
  }

  async function get() {
    try {
      const { data } = await client.query({
        query: GET_CARD,
        variables: {
          phone,
        },
      });

      return getUndef(() => data.customerCardNumber);
    } catch (error) {
      return;
    }
  }

  return await get();
}
