import { css } from "styled-components";

export const cover = css`
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
`;

export const coverTop = css`
  object-fit: cover;
  object-position: center top;
  font-family: "object-fit: cover; object-position: center;";
`;

export const contain = css`
  object-fit: contain;
  object-position: center;
  font-family: "object-fit: contain; object-position: center;";
`;

const fitImages = css`
  .cover {
    ${cover}
  }
  .cover-top {
    ${coverTop}
  }
  .contain {
    ${contain}
  }
`;

export default fitImages;
