import React, { createContext, Component } from "react";
import { CSSTransition } from "react-transition-group";

export const ErrorsContext = createContext<any>({});

export class ErrorsProvider extends Component<any, any> {
  timout = null;

  constructor(props) {
    super(props);

    this.state = {
      message: "",
      display: false,
    };
  }

  displayErr = (message) => {
    this.timout = setTimeout(() => {
      this.setState({
        message,
        display: true,
      });
      setTimeout(this.hideError, 8000);
    }, 250);
  };

  hideError = () => {
    if (this.timout) {
      clearTimeout(this.timout);
      this.timout = null;
    }
    this.setState({
      display: false,
    });
  };

  clearError = () => {
    this.setState({
      message: "",
    });
  };

  render() {
    const { children } = this.props;
    const { message, display } = this.state;

    return (
      <ErrorsContext.Provider
        value={{
          displayErr: this.displayErr,
          hideError: this.hideError,
        }}
      >
        <div className="error-position">
          <CSSTransition
            in={display}
            timeout={300}
            classNames="error"
            unmountOnExit
            onExited={this.clearError}
          >
            <div className="error-wrap">
              <p className="error-text">{message}</p>
            </div>
          </CSSTransition>
        </div>
        {children}
        <style jsx>{`
          .error-position {
            z-index: 10;
            position: fixed;
            right: 2%;
            bottom: 5%;
          }

          .error-wrap {
            padding: 20px;
            background-color: #ef1136;
            max-width: 340px;
          }

          .error-text {
            margin: 0;
            color: #fff;
          }

          .error-enter {
            opacity: 0.01;
            transform: scale(0.9);
          }

          .error-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: 0.225s ease-out;
          }

          .error-exit {
            opacity: 1;
            transform: scale(1);
          }

          .error-exit-active {
            opacity: 0.01;
            transform: scale(0.9);
            transition: 0.2s ease-in;
          }
        `}</style>
      </ErrorsContext.Provider>
    );
  }
}

export const ErrorsConsumer = ErrorsContext.Consumer;
