import { css } from "styled-components";
import { GREEN } from "./constants";
import { opacityHover } from "./atoms";

const fonts = css`
  body {
    line-height: 1.6;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: #2b2b2b;
  }

  .kabel,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "KabelCTT Book", Arial, Helvetica, sans-serif;
  }

  .kabel {
    font-weight: bold;
  }

  h1,
  h2,
  h3 {
    line-height: 1;
  }

  h4,
  h5 {
    line-height: 1.5;
  }

  .h1,
  h1 {
    font-size: 50px;

    @media screen and (min-width: 375px) {
      font-size: calc(50px + 40 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 90px;
    }
  }

  .h1_small {
    font-size: 36px;

    @media screen and (min-width: 375px) {
      font-size: calc(36px + 24 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 60px;
    }
  }

  .h2,
  h2 {
    font-size: 36px;

    @media screen and (min-width: 375px) {
      font-size: calc(36px + 14 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 50px;
    }
  }

  .h3,
  h3 {
    font-size: 30px;

    @media screen and (min-width: 375px) {
      font-size: calc(30px + 10 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 40px;
    }
  }

  .h4,
  h4 {
    font-size: 22px;

    @media screen and (min-width: 375px) {
      font-size: calc(22px + 4 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 26px;
    }
  }

  .smallerH4 {
    font-size: 18px;

    @media screen and (min-width: 375px) {
      font-size: calc(18px + 4 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 22px;
    }
  }

  .h5,
  h5 {
    font-size: 18px;
  }

  .font-big {
    font-size: 16px;
    line-height: 150%;

    @media screen and (min-width: 375px) {
      font-size: calc(16px + 6 * ((100vw - 375px) / 625));
    }

    @media screen and (min-width: 1000px) {
      font-size: 16px;
    }
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }

  .link,
  .user-content a {
    ${opacityHover};
    color: ${GREEN};
  }

  .user-content li {
    position: relative;
    padding-left: 24px;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      left: 8px;
      top: 0.75em;
      width: 6px;
      height: 6px;
      background-color: ${GREEN};
      border-radius: 50%;
      content: "";
    }
  }

  .link {
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.2;
    margin: 0.4em 0;
    font-weight: 900;
  }

  h1 {
    margin: 0.1em 0;
  }

  ul,
  ol,
  table,
  p {
    margin: 0.5em 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  ul,
  ol,
  table,
  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default fonts;
