export const GA_TRACKING_ID = "UA-172761588-1";
export const GA_TRACKING_ID_BELORETSK = "G-LCHSQXTF5V";

declare global {
  interface Window {
    dataLayer: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url, host) => {
  const id =
    host === "beloretsk.mikafood.ru" || host === "test-beloretsk.mikafood.ru"
      ? GA_TRACKING_ID_BELORETSK
      : GA_TRACKING_ID;
  gtag("config", id, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const eventConversion = ({ transaction_id, value }) => {
  gtag("event", "conversion", {
    currency: "RUB",
    value,
    transaction_id,
  });
};

export const event = ({
  action,
  category,
  value,
}: {
  action: string;
  category: string;
  value?: string | number;
}) => {
  gtag("event", action, {
    event_category: category,
    value,
  });
};

export const eventWithoutValue = ({
  action,
  category,
}: {
  action: string;
  category: string;
}) => {
  gtag("event", action, {
    event_category: category,
  });
};
