import React, { FC, useContext } from "react";
import styled from "styled-components";
import Link from "next/link";

import CheckoutContext from "checkout";
import { opacityHover } from "../styles/atoms";
import getUndef from "../../lib/get-undef";
import separateNumber from "../../lib/separate-number";
import { RED } from "../styles/constants";

const HeaderBasket: FC<{}> = () => {
  const { checkout, optimisticLineItems, ingredientIds } = useContext(
    CheckoutContext
  );

  const items = optimisticLineItems
    ? optimisticLineItems.filter(({ variant: { id } }) => {
        return !ingredientIds.find((ingredientId) => ingredientId === id);
      })
    : [];

  const totalQuantity = items.reduce(
    (acc, item) => acc + item.expectedQuantity,
    0
  );

  const totalPrice = getUndef(() => checkout.totalPrice);

  if (!totalPrice || !totalQuantity) {
    return null;
  }

  return (
    <Link href="/order">
      <Basket href="/order">
        <span className="icon" />
        {totalPrice && totalQuantity ? (
          <span className="wrap">
            <span className="price">{separateNumber(totalPrice)} ₽</span>
            <span className="total">{totalQuantity}шт.</span>
          </span>
        ) : null}
        {totalQuantity && <span className="total-mobile">{totalQuantity}</span>}
      </Basket>
    </Link>
  );
};

const Basket = styled.a`
  position: relative;
  display: inline-flex;
  align-items: center;
  line-height: 1.3;
  margin: 0 10px;
  ${opacityHover};

  .icon,
  .wrap,
  .price,
  .total {
    display: block;
  }

  .icon {
    height: 36px;
    width: 30px;
    background: url("/static/icons/cart.svg") no-repeat center;
  }

  .wrap {
    margin-left: 10px;
  }

  .price {
    font-size: 14px;
  }

  .total {
    font-size: 12px;
    opacity: 0.5;
  }

  .total-mobile {
    display: none;
  }

  @media (max-width: 980px) {
    .wrap {
      display: none;
    }

    .total-mobile {
      display: block;
      position: absolute;
      top: -3px;
      right: -8px;
      display: inline-block;
      font-size: 12px;
      color: #fff;
      background-color: ${RED};
      border-radius: 50%;
      line-height: 20px;
      padding: 0 6px;
    }
  }

  @media (max-width: 800px) {
    .icon {
      background-size: 26px;
    }
  }
`;

export default HeaderBasket;
