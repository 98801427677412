import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import { GREEN, CONTENT_COLOR, GRAY_LIGHT } from "../styles/constants";
import Header from "./Header";
import { easeIn, easeOut } from "../styles/atoms";
import Footer from "./Footer";
import UAParser from "ua-parser-js";
import AppBanner from "../common/appBanner";
import { useLocalStorage } from "../hooks/use-local-storage";

interface Props {
  main: boolean | undefined;
}

const Layout: FC<Props> = ({ children, main }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [os, setOs] = useState("");
  const [showAppBanner, setShowAppBanner] = useState(false);
  const [lsShow, setLsShow] = useLocalStorage("show-mika-app-banner", true);

  useEffect(() => {
    if (window) {
      const parser = new UAParser();
      setOs(parser.getOS()?.name.toLowerCase());
    }
  }, []);

  useEffect(() => {
    if (os && (os === "android" || os === "ios") && lsShow) {
      setShowAppBanner(true);
    }
  }, [os, lsShow]);

  const closeHandler = () => {
    setLsShow(false);
    setShowAppBanner(false);
  };

  return (
    <Container>
      {showAppBanner && (
        <AppBanner isAndroid={os === "android"} closeHandler={closeHandler} />
      )}
      <Aside className={menuIsOpen ? "open" : ""}>
        <ScrollArea>
          <Navbar setMenuIsOpen={setMenuIsOpen} />
        </ScrollArea>
      </Aside>
      <Content main={main}>
        <HeaderWrap>
          <Header menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        </HeaderWrap>
        <Main>{children}</Main>
        <Footer main={main} />
      </Content>
    </Container>
  );
};

const ASIDE_WIDTH = 185;
const HEADER_HEIGHT = 60;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  padding-left: ${ASIDE_WIDTH}px;
  padding-top: ${HEADER_HEIGHT}px;

  @media (max-width: 1200px) {
    padding-left: 0;
  }
`;
const Aside = styled.aside`
  z-index: 100;
  width: ${ASIDE_WIDTH}px;
  position: fixed;
  left: 0;
  top: ${HEADER_HEIGHT}px;
  bottom: 0;
  display: flex;
  /* overflow-y: auto; */
  box-shadow: 3px 0px 12px rgba(0, 0, 0, 0.05);

  @media (max-width: 1200px) {
    background-color: #fff;
    transform: translate3d(-100%, 0, 0);
    ${easeIn};

    &.open {
      transform: translate3d(0, 0, 0);
      ${easeOut};
    }
  }
  @media (max-width: 600px) {
    bottom: -100px;

    width: 100%;
    transform: translate3d(0, -20px, 0);
    border-top: 1px solid ${GRAY_LIGHT};
    opacity: 0;
    visibility: hidden;
    color: #fff;
    background-color: ${GREEN};
    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
`;
interface ContentInterface {
  main: boolean;
}
const Content = styled.div<ContentInterface>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  background-color: ${CONTENT_COLOR};
`;
const HeaderWrap = styled.header`
  z-index: 110;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: ${GREEN};
  height: ${HEADER_HEIGHT}px;
`;
const Main = styled.main`
  flex-grow: 1;
  padding: 35px 0 90px;
  @media (max-width: 980px) {
    padding: 25px 0 50px;
  }
`;

const ScrollArea = styled.div`
  overflow: auto;
  width: 100%;

  @media (max-width: 600px) {
    padding: 20px 0 125px;
  }
`;

export default Layout;
