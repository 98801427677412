import gql from "graphql-tag";

export const GET_BAR = gql`
  query getBarCollections($slug: String!) {
    shop {
      id
      navigations(where: { slug: $slug }) {
        id
        slug
        lineItems(orderBy: sort_ASC) {
          name
          description
          id
          params
          slug
          metafields {
            key
            value
          }
        }
      }
    }
  }
`;
