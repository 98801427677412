import React from "react";
import App from "next/app";
import dynamic from "next/dynamic";
import { ApolloProvider } from "react-apollo";
import { withId, YMInitializer } from "react-yandex-metrika";
import { withRouter } from "next/router";
import * as Sentry from "@sentry/node";

import dayjs from "dayjs";
import "dayjs/locale/ru";
dayjs.locale("ru");

// import "swiper/css/swiper.min.css";
import "../components/styles/custom-swiper.css";
import "rc-select/assets/index.css";

import { CheckoutProvider } from "checkout";
import initCheckout from "../lib/initCheckout";
import withApolloClient from "../lib/with-apollo-client";
import initAuth from "../lib/initAuth";
import Layout from "../components/layout/Layout";
import GlobalStyles from "../components/styles/global";
import { AuthorizationProvider } from "../components/context/Authorization";
import { ErrorsProvider } from "../components/context/Errors";
import HelmetComponent from "../components/layout/HelmetComponent";
import { CityProvider, Metrica } from "../components/context/CityContext";

import * as gtag from "../lib/gtag";
import { getYmAccountId } from "../lib/get-ym-account-id";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

Sentry.init({
  dsn: "https://468072a6a9474521a12efaaadf43c629@o381581.ingest.sentry.io/5209400",
});

const DynamicCookiePopUp = dynamic(
  () => import("../components/layout/Cookie"),
  {
    ssr: false,
  }
);

class MyApp extends App<any, any, any> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorEventId: undefined,
      ymInit: false,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      pageProps,
      host: process.browser ? window.location.host : ctx.req.headers.host,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        ymInit: true,
      });
      setTimeout(() => {
        try {
          const { router, host } = this.props;
          const ymAccountId = getYmAccountId(host);

          withId(ymAccountId)("hit", router.asPath);
          router.events.on("routeChangeComplete", (url) => {
            gtag.pageview(url, host);
            withId(ymAccountId)("hit", url);
          });
        } catch (error) {
          console.error(error);
        }
      }, 0);
    }, 5000);
  }

  render() {
    const { ymInit } = this.state;
    const { Component, pageProps, apolloClient, host } = this.props;
    const { main } = pageProps;

    return (
      <>
        <noscript>
          <img
            src="https://vk.com/rtrg?p=VK-RTRG-237273-4cwCy"
            style={{ position: "fixed", left: "-999px" }}
            alt=""
          />
        </noscript>

        <style jsx global>{`
          @font-face {
            font-family: "KabelCTT Book";
            src: url("/static/fonts/hinted-KabelC-Demi.woff2") format("woff2"),
              url("/static/fonts/hinted-KabelC-Demi.woff") format("woff");
            font-weight: bold;
            font-style: normal;
            font-display: block;
          }

          @font-face {
            font-family: "Roboto";
            src: url("/static/fonts/Roboto-Regular.woff2") format("woff2"),
              url("/static/fonts/Roboto-Regular.woff") format("woff");
            font-weight: normal;
            font-style: normal;
            font-display: block;
          }

          @font-face {
            font-family: "Roboto";
            src: url("/static/fonts/Roboto-Bold.woff2") format("woff2"),
              url("/static/fonts/Roboto-Bold.woff") format("woff");
            font-weight: bold;
            font-style: normal;
            font-display: block;
          }
        `}</style>

        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        >
          <CityProvider host={host}>
            <GlobalStyles />
            <HelmetComponent />
            <ErrorsProvider>
              <ApolloProvider client={apolloClient}>
                <CheckoutProvider
                  client={apolloClient}
                  initCheckout={initCheckout}
                >
                  <AuthorizationProvider
                    client={apolloClient}
                    initAuth={initAuth}
                  >
                    <Layout main={main}>
                      <DynamicCookiePopUp />
                      <Component {...pageProps} />
                    </Layout>
                  </AuthorizationProvider>
                </CheckoutProvider>
              </ApolloProvider>
            </ErrorsProvider>

            {ymInit && (
              <YMInitializer
                accounts={[Metrica.Magnitogorsk, Metrica.Beloretsk]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                  trackHash: true,
                  defer: true,
                }}
                version="2"
              />
            )}
          </CityProvider>
        </GoogleReCaptchaProvider>
      </>
    );
  }
}

export default withRouter(withApolloClient(MyApp));
